<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import values from 'lodash/values';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton, GenericModalComponent, MonthSelectorComponent, StoreNotificationComponent,
} from '@/components';
import { CODE_ASSOCIATION, CONTRACT_TYPES, FUEL_CARD_TYPES } from './const';
import { FUEL_CARD_SCOPES } from '../../store/FuelCardsModule';

export default {
  name: 'FuelCardForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiSelect,
    MuiTextarea,
    MuiValidationWrapper,
    StoreNotificationComponent,
    MonthSelectorComponent,
  },
  props: {
    fuelCardUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      originalInputs: {},
      inputs: {
        type: FUEL_CARD_TYPES.primary,
        number: '',
        company: '',
        description: '',
        fuelPin: '',
        active: false,
        contractType: CONTRACT_TYPES.internal,
        codeAssociation: CODE_ASSOCIATION.list,
        expiryDate: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      permissions: state => get(state, 'user.data.permissions'),
      activeOperatorUuid: state => state.operators.active.uuid,
    }),
    ...mapState(DOMAINS_MODEL.fleet.fuelCards, {
      fuelCard: state => state.fuelCard.data.data,
      newFuelCardStatus: state => state.newFuelCard.STATUS,
    }),
    allowSecondaryFuelCard() {
      return includes(this.permissions, 'allow_secondary_fuelcard');
    },
    modalTitle() {
      return this.fuelCardUuid ? 'Edit fuel card' : 'Add fuel card';
    },
    areRequiredFieldsFilled() {
      return this.inputs.number
        && this.inputs.company
        && this.inputs.fuelPin;
    },
    hasSameValues() {
      return isEqual(this.inputsForRequest(this.inputs), this.inputsForRequest(this.originalInputs));
    },
  },
  async created() {
    this.isInitialized = false;
    this.FUEL_CARD_TYPES = FUEL_CARD_TYPES;
    this.CODE_ASSOCIATION = CODE_ASSOCIATION;
    this.CONTRACT_TYPES = CONTRACT_TYPES;
    this.FUEL_CARD_SCOPES = FUEL_CARD_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.MONTH_FORMAT = 'YYYY-MM';
    this.validDateRange = {
      start: moment().add(1, 'month').startOf('month'),
      end: moment().add(10, 'years'),
    };

    this.resetState([FUEL_CARD_SCOPES.fuelCard, FUEL_CARD_SCOPES.newFuelCard]);
    if (this.fuelCardUuid) {
      await this.getFuelCard(this.fuelCardUuid);
      this.inputs.type = get(this, 'fuelCard.type');
      this.inputs.number = get(this, 'fuelCard.number');
      this.inputs.company = get(this, 'fuelCard.company');
      this.inputs.description = get(this, 'fuelCard.description');
      this.inputs.fuelPin = get(this, 'fuelCard.fuelPin');
      this.inputs.active = !!get(this, 'fuelCard.active');
      this.inputs.contractType = get(this, 'fuelCard.contractType');
      this.inputs.codeAssociation = get(this, 'fuelCard.codeAssociation');
      this.inputs.expiryDate = get(this, 'fuelCard.expiryDate') ? moment(get(this, 'fuelCard.expiryDate')) : null;
      this.originalInputs = cloneDeep(this.inputs);
    }

    this.isInitialized = true;
  },
  methods: {
    map,
    values,
    sentenceCase,
    async request() {
      this.clearErrors();
      const request = this.fuelCardUuid ? this.putFuelCard : this.postFuelCard;
      const params = this.fuelCardUuid
        ? { fuelCardUuid: this.fuelCardUuid, data: this.inputsForRequest(this.inputs) }
        : this.inputsForRequest(this.inputs);
      await request(params);

      if (!this.newFuelCardStatus.ERROR) {
        const message = this.fuelCardUuid
          ? 'Fuel card edited successfully!'
          : 'Fuel card successfully <span class="emobg-font-weight-bold">added</span>.';
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback();
        this.resetState([FUEL_CARD_SCOPES.fuelCard, FUEL_CARD_SCOPES.newFuelCard]);
      }
    },
    validateDate(momentObject) {
      if (momentObject) {
        const date = cloneDeep(momentObject);

        this.$nextTick(() => {
          if (date.isBefore(this.validDateRange.start)) {
            this.inputs.expiryDate = cloneDeep(this.validDateRange.start);
          }
        });
      }
    },
    inputsForRequest(data = {}) {
      const inputs = cloneDeep(data);
      inputs.csOperatorUuid = this.activeOperatorUuid;
      inputs.expiryDate = inputs.expiryDate ? inputs.expiryDate.format(this.MONTH_FORMAT) : null;

      return inputs;
    },
    ...mapActions(DOMAINS_MODEL.fleet.fuelCards, [
      'getFuelCard',
      'postFuelCard',
      'putFuelCard',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.fuelCards, [
      'resetState',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="FuelCardForm"
    data-test-id="fuel_card-form"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.fuelCards"
        :scope="FUEL_CARD_SCOPES.newFuelCard"
        :is-editing="!!fuelCardUuid"
        element="fuel card"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <ui-loader
        v-if="!isInitialized"
        label="Loading fuelcard..."
        data-test-id="loader"
      />
      <MuiValidationWrapper
        v-else
        data-test-id="validation"
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="row">
          <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none">
            <div class="pr-2">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Number*
              </label>
              <MuiInputText
                v-model="inputs.number"
                placeholder="Enter the fuel card number"
                name="number"
                class="w-100 mb-4"
                data-test-id="number-input"
              />

              <MuiSelect
                v-model="inputs.codeAssociation"
                :options="map(CODE_ASSOCIATION, code => ({label: sentenceCase(code), value: code }))"
                name="codeAssociation"
                placeholder="Select the association code"
                label="Association code*"
                class="w-100 mb-4"
                data-test-id="association_code-select"
              />

              <label class="d-block emobg-font-weight-semibold mb-1">
                PIN*
              </label>
              <MuiInputText
                v-model="inputs.fuelPin"
                placeholder="Enter the fuel PIN"
                name="fuelPin"
                class="w-100 mb-4"
                data-test-id="fuel_pin-input"
              />

              <MonthSelectorComponent
                v-model="inputs.expiryDate"
                :format="MONTH_FORMAT"
                label="Expiry date"
                hide-icon
                disable-typing
                class="mb-4"
                data-test-id="expiry_date-select"
                @change="momentObject => validateDate(momentObject)"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="pl-2">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Active
              </label>
              <ui-toggle
                :value="inputs.active"
                :text="inputs.active ? 'Active' : 'Inactive'"
                label="Status"
                name="status"
                class="mb-4 py-1 d-block"
                data-test-id="active-toggle"
                @changevalue="({ detail }) => inputs.active = detail"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Company*
              </label>
              <MuiInputText
                v-model="inputs.company"
                placeholder="Enter the fuel card number"
                name="company"
                class="w-100 mb-4"
                data-test-id="company-input"
              />

              <MuiSelect
                v-model="inputs.contractType"
                :options="map(CONTRACT_TYPES, type => ({label: sentenceCase(type), value: type }))"
                name="contractType"
                placeholder="Select the contract type"
                label="Contract type*"
                class="w-100 mb-4"
                data-test-id="contract_type-select"
              />

              <div
                v-if="allowSecondaryFuelCard"
                class="mb-4"
              >
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Fuel card type*
                </label>
                <ui-radio
                  v-for="type in values(FUEL_CARD_TYPES)"
                  :key="type"
                  :value="inputs.type"
                  :option="type"
                  :caption="sentenceCase(type)"
                  class="d-block mb-1"
                  name="type"
                  :data-test-id="`type_${type}-button`"
                  @changevalue="() => inputs.type = type"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <label class="d-block emobg-font-weight-bold mb-1">
              Description
            </label>
            <MuiTextarea
              v-model="inputs.description"
              v-validate="{
                isRequired: true,
                isMinLength: {
                  message: 'We need at least 6 characters',
                  length: 6,
                }
              }"
              name="description"
              rows="4"
              data-test-id="description-textarea"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton
            data-test-id="close_modal-button"
            @click="() => $emit('closeModal')"
          />
        </div>

        <div class="d-flex justify-content-center">
          <ui-button
            :disabled="!areRequiredFieldsFilled || hasSameValues"
            :loading="newFuelCardStatus.LOADING"
            class="wmin-initial"
            data-test-id="request-button"
            @clickbutton="request"
          >
            {{ fuelCardUuid ? 'Save' : 'Create' }}
          </ui-button>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
